import { setMessageStream } from "redux/chat/chat.slice";
import {
  addSummaryStream,
  addTranscriptStream,
} from "redux/settings/settings.slice";
import io from "socket.io-client";

const chatSocketMiddleware = (store: any) => (next: any) => {
  const token = localStorage.getItem("access_token");
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}/chat`, {
    query: {
      token,
    },
  });

  socket.on("user-socket", (id) => {
    localStorage.setItem("socketIdChat", id);
  });

  socket.on("chat", ({ id, type, message, done }) => {
    store.dispatch(setMessageStream({ id, type, message, done }));
  });

  socket.on("generate-transcript", ({ directory_id, transcript }) => {
    store.dispatch(addTranscriptStream({ directory_id, transcript }));
  });

  socket.on("generate-summary", ({ directory_id, summary }) => {
    console.log(summary);
    store.dispatch(addSummaryStream({ directory_id, summary }));
  });

  return (action: any) => {
    switch (action.type) {
      case "chat": {
        if (action.payload) {
        }
        return next(action);
      }
      default:
        return next(action);
    }
  };
};

export default chatSocketMiddleware;
