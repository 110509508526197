import { Route, RouterProvider } from "react-router-dom";
import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "./pages/login";
import AppManager from "./pages/app";
import Welcome from "./pages/app/welcome";
import React from "react";
// import Chat from "./pages/app/chat";
// import ChatPDF from "pages/chat-pdf";
// import ChatBox from "pages/app/chat/components/chat-box";
// import Project from "./pages/app/project";
import Register from "pages/register";
import Subscription from "pages/subscription";
// import Shortcast from "pages/shortcast";
import ShortcastWelcome from "pages/shortcast/welcome";
import TargetShortcast from "pages/shortcast/target-shortcast";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<AppManager />}>
        <Route index element={<Welcome />} />
        <Route path=":id" element={<ShortcastWelcome />} />
        <Route path=":id/transcript" element={<TargetShortcast />} />
        {/* <Route path="project/:project_id" element={<Project />} />
        <Route path="chat" element={<Chat />}>
          <Route index element={<ChatBox />} />
        </Route> */}
      </Route>
      {/* <Route path="/pdf" element={<ChatPDF />}>
        <Route index path=":file_id" />
      </Route> */}
      <Route path="/subscription" element={<Subscription />} />
      <Route path="login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      {/* <Route path="shortcast" element={<Shortcast />}>
        <Route path=":id" element={<ShortcastWelcome />} />
        <Route path=":id/transcript" element={<TargetShortcast />} />
      </Route> */}
    </Route>
  )
);

function App(props: any) {
  return (
    <>
      {" "}
      <ToastContainer />
      <RouterProvider router={router} />
    </>
  );
}

export default React.memo(App, () => {
  return false;
});
