/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from "react-router-dom";
import Head from "../../layouts/head";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setShow } from "../../redux/sidebar/sidebar.slice";
import Shortcast from "pages/shortcast";
// import {
//   getCategoriesAsync,
//   getLanguagesAsync,
//   getProjectsAsync,
//   getProjectChatsAsync,
//   getPromptChatsAsync,
// } from "../../redux/settings/settings.action";

const View = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(getCategoriesAsync());
    // dispatch(getProjectsAsync());
    // dispatch(getLanguagesAsync());
    // dispatch(getProjectChatsAsync());
    // dispatch(getPromptChatsAsync());
  }, []);

  useEffect(() => {
    const responsive = () => {
      if (window.innerWidth > 640) {
        dispatch(setShow(true));
      } else {
        dispatch(setShow(false));
      }
    };
    window.addEventListener("resize", responsive);

    return () => {
      window.removeEventListener("resize", responsive);
    };
  });

  return (
    <div className="flex flex-col md:flex-row">
      <Shortcast />
      <div className="flex flex-col w-full">
        <Head />
        <Outlet />
      </div>
    </div>
  );
};

export default React.memo(View, () => {
  return true;
});
