/* eslint-disable react-hooks/exhaustive-deps */
// import { setUser } from 'modules/users/users.reducers';
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../services/users";
import { useAppDispatch } from "../redux/hooks";
import { setAuth } from "../redux/settings/settings.slice";
import { useQuery } from "utils/useQuery";
import StripeService from "services/stripe";
import { changeSubscription, createCount, isExist } from "services/count";

const withAuth = (WrapperComponent: any) => {
  return function Auth(props: JSX.IntrinsicAttributes) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const query = useQuery();
    const PAGE_NOT_AUTH = ["login", "register", "forgot-password"];

    useEffect(() => {
      const didMount = async () => {
        const token = localStorage.getItem("access_token");
        const querystring = query.toString();
        const session_id = query.get("session_id");
        if (!token) {
          navigate(`/login?${querystring}`);
        } else {
          UserService.getUserAuth()
            .then((data: any) => {
              const { user } = data;
              if (user) {
                if (session_id) {
                  StripeService.lineItem({
                    query: { sessionId: session_id },
                  }).then((data: any) => {
                    if (data.payment_status === "paid") {
                      isExist({
                        query: {
                          user_id: user._id,
                        },
                      }).then((exist: any) => {
                        if (!exist) {
                          createCount({
                            body: {
                              price_id: data.id,
                              user_id: user._id,
                            },
                          });
                        } else {
                          changeSubscription({
                            body: {
                              count_id: exist._id,
                              price_id: data.id,
                            },
                          });
                        }
                        navigate("/");
                      });
                    }
                  });
                }

                dispatch(setAuth(user));
                if (
                  !PAGE_NOT_AUTH.some((url) => location.pathname.includes(url))
                ) {
                  return;
                } else {
                  return navigate("/");
                }
              } else {
                localStorage.removeItem("access_token");
                return navigate(`/login?${query}`);
              }
            })
            .catch((error) => {
              localStorage.removeItem("access_token");
              return navigate(`/login?${query}`);
            });
        }
      };

      didMount();
    }, []);

    return <WrapperComponent {...props} />;
  };
};

export default withAuth;
