import TextArea from "components/textarea";
import Button from "components/button";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "assets/loading-animation.json";

const View = ({
  data,
  onGenerate,
}: {
  data: {
    summary: string;
    status: boolean;
  };
  onGenerate?: () => Promise<void>;
}) => {
  return (
    <div className="h-[calc(100vh-100px)] overflow-auto">
      {data.status && data.summary && (
        <div className="border p-2 rounded-lg border-purple-300 bg-purple-50 max-h-[calc(100vh-100px)] overflow-auto">
          <TextArea
            value={data.summary?.trim()}
            ckey="text"
            onChange={() => {}}
            editable={false}
            className="!bg-transparent"
          />
        </div>
      )}
      {!data.status ? (
        <>
          <p className="font-bold text-lg text-icon-default text-center mt-20">
            Our AI is working; you can close this window and return after 1-2
            minutes.
          </p>
          <Player autoplay loop src={LoadingJson} className="h-32" />
        </>
      ) : (
        <div className="mt-10">
          <Button
            name="Generate"
            onClick={async () => {
              onGenerate && (await onGenerate());
            }}
            className="!w-1/2 m-auto mt-5 bg-typera-primary"
          />
        </div>
      )}
    </div>
  );
};

export default View;
