import { AddIcon } from "assets/icons";
import Tabs from "components/tabs";
import { useEffect, useState } from "react";
import Transcript from "./components/transcript";
import Summary from "./components/summary";
import Chat from "./components/chat";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { getDirectories } from "redux/settings/settings.selector";
import DirectoryService from "services/directories";
import {
  addTranscriptStream,
  updateTranscript,
} from "redux/settings/settings.slice";
import { resetMessageShortcast } from "redux/chat-directories/chat-directories.slice";

const View = () => {
  const directories = useAppSelector(getDirectories);
  const { id } = useParams();
  const [targetShortcast, setTargetShortcast] = useState<any>(null);
  const transcript = targetShortcast?.transcript;
  const [activeTab, setActiveTab] = useState("transcript");
  const dispatch = useAppDispatch();
  const isMobile = window.innerWidth <= 640;

  const tabs = [
    {
      key: "transcript",
      name: "Transcript",
      icon: <AddIcon />,
      onAction: () => {
        setActiveTab("transcript");
      },
    },
    {
      key: "summary",
      name: "Summary",
      icon: <AddIcon />,
      onAction: () => {
        setActiveTab("summary");
      },
    },
    isMobile && {
      key: "chat",
      name: "Chat",
      icon: <AddIcon />,
      onAction: () => {
        setActiveTab("chat");
      },
    },
  ];

  useEffect(() => {
    setTarget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, directories]);

  useEffect(() => {
    getTargetTranscript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getTargetTranscript = async () => {
    if (id) {
      const transcript = await DirectoryService.getTranscriptOfDirectory({
        params: {
          id,
        },
      });
      dispatch(addTranscriptStream({ directory_id: id, transcript }));
    }
  };

  const setTarget = async () => {
    if (id) {
      setTargetShortcast(
        directories
          .filter((item) => item.type === "shortcast")
          .find((item: any) => item._id === id)
      );
      dispatch(resetMessageShortcast());
    }
  };

  const generateSummary = async () => {
    try {
      dispatch(
        updateTranscript({
          id: transcript?._id,
          body: {
            summary: {
              ...transcript?.summary,
              status: false,
            },
          },
        })
      );
      DirectoryService.generateSummary({
        params: { id: targetShortcast?._id },
      });
    } catch (error) {}
  };

  const activeChatAndGenerateQuestionHandler = async () => {
    try {
      const data = await DirectoryService.activeChatAndGenerateQuestion({
        params: { id: targetShortcast?._id },
      });
      if (data) {
        dispatch(
          updateTranscript({
            id: transcript?._id,
            body: { example_questions: data, isActiveChat: true },
          })
        );
      }
    } catch (error) {}
  };

  const renderActive = () => {
    switch (activeTab) {
      case "transcript": {
        return (
          <Transcript
            data={{
              url: transcript?.source,
              transcript: transcript?.transcript?.content,
              youtube_id: transcript?.youtube_id,
            }}
          />
        );
      }
      case "summary": {
        return (
          <Summary
            onGenerate={generateSummary}
            data={{
              summary: transcript?.summary?.content,
              status: transcript?.summary?.status,
            }}
          />
        );
      }
      case "chat": {
        return (
          <Chat
            target={targetShortcast}
            activeChat={transcript?.isActiveChat}
            onActive={activeChatAndGenerateQuestionHandler}
            exampleQuestions={transcript?.example_questions}
          />
        );
      }
    }
  };

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-3 mt-16 md:mt-0 ">
      <div className="m-3 lg:col-span-7">
        <>
          <Tabs data={tabs.filter((item) => item)} target={activeTab} />
          <div className="mt-2">{renderActive()}</div>
        </>
      </div>
      {!isMobile && (
        <div className="lg:col-span-5">
          <Chat
            target={targetShortcast}
            activeChat={transcript?.isActiveChat}
            onActive={activeChatAndGenerateQuestionHandler}
            exampleQuestions={transcript?.example_questions}
          />
        </div>
      )}
    </div>
  );
};

export default View;
