import io from "socket.io-client";
import {
  setResultsStream,
  setReResultsStream,
  setLoading,
} from "../results/result.slice";
import { getDirectoriesAsync } from "redux/settings/settings.action";

const resultSocketMiddleware = (store: any) => (next: any) => {
  const token = localStorage.getItem("access_token");
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}/results`, {
    query: {
      token,
    },
  });

  socket.on("user-socket", (id) => {
    localStorage.setItem("socketId", id);
  });

  socket.on("generate-result", (data) => {
    store.dispatch(setResultsStream(data));
  });

  socket.on("re-generate-result", (data) => {
    store.dispatch(setReResultsStream(data));
  });

  socket.on("generate-done", (isDone) => {
    store.dispatch(setLoading(!isDone));
  });

  socket.on("directory-pending", (data) => {
    console.log(data);
    if (data) {
      const user_id = localStorage.getItem("user_id");
      if (user_id === data?.user_id) {
        store.dispatch(getDirectoriesAsync("shortcast"));
      }
    }
  });

  socket.on("directory-finished", (data) => {
    console.log(data);
    if (data) {
      const user_id = localStorage.getItem("user_id");
      if (user_id === data?.user_id) {
        store.dispatch(getDirectoriesAsync("shortcast"));
      }
    }
  });

  return (action: any) => {
    switch (action.type) {
      case "result/generate": {
        if (action.payload) {
        }
        return next(action);
      }
      default:
        return next(action);
    }
  };
};

export default resultSocketMiddleware;
