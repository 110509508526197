import { useState } from "react";
import { IOption } from "../../types/types";
import { twMerge } from "tailwind-merge";

interface IProps {
  value: string;
  ckey?: string;
  data: IOption[];
  defaultValue?: string;
  onSelect: (e?: any) => void;
  optionValue?: string;
  optionLabel?: string;
  maxHeight?: number;
  className?: string;
  optionsClassName?: string;
}

const View = ({
  value,
  ckey,
  data,
  defaultValue,
  optionValue = "key",
  maxHeight,
  className,
  optionsClassName,
  onSelect,
}: IProps) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow((state) => !state);
  };

  const UpperFirstChar = (word: string | undefined) => {
    return word && word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <div className="relative text-typera-primary">
      <div
        onClick={handleShow}
        className={twMerge(
          "w-full border py-2 h-10 px-4 shadow-sm rounded bg-white mt-2 cursor-pointer flex items-center justify-between",
          className
        )}
      >
        <p className="text-sm font-medium leading-6">
          {UpperFirstChar(
            data?.find(
              (item: any) => item[optionValue] === (value || defaultValue)
            )?.name
          )}
        </p>
        <i
          className={`bx bx-chevron-right text-xl text-typera-secondary trasition-transform duration-300 ${
            show && "rotate-90"
          }`}
        ></i>
      </div>
      {show && (
        <div
          className={twMerge(
            "w-full border shadow-sm rounded bg-white mt-2 absolute z-50 overflow-auto",
            optionsClassName
          )}
          style={{ maxHeight: `${maxHeight}px` }}
        >
          {data.map((item: any) => {
            return (
              <div
                onClick={() => {
                  setShow(false);
                  onSelect(item[optionValue]);
                }}
                key={item[optionValue]}
                className="hover:bg-gray-primary py-2 px-4 cursor-pointer font-medium text-sm"
              >
                {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default View;
