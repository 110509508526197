export const LANGUAGE_OPTIONS = [
  { name: "Bulgarian", value: "bg" },
  { name: "Catalan", value: "ca" },
  { name: "Chinese", value: "zh" },
  { name: "Czech", value: "cs" },
  { name: "Danish", value: "da" },
  { name: "Dutch", value: "nl" },
  { name: "English", value: "en" },
  { name: "Estonian", value: "et" },
  { name: "Finnish", value: "fi" },
  { name: "French", value: "fr" },
  { name: "German", value: "de" },
  { name: "Greek", value: "el" },
  { name: "Hindi", value: "hi" },
  { name: "Hungarian", value: "hu" },
  { name: "Indonesian", value: "id" },
  { name: "Italian", value: "it" },
  { name: "Japanese", value: "ja" },
  { name: "Korean", value: "ko" },
  { name: "Polish", value: "pl" },
  { name: "Portuguese", value: "pt" },
  { name: "Romanian", value: "ro" },
  { name: "Russian", value: "ru" },
  { name: "Slovak", value: "sk" },
  { name: "Spanish", value: "es" },
  { name: "Swedish", value: "sv" },
  { name: "Thai", value: "th" },
  { name: "Turkish", value: "tr" },
  { name: "Ukrainian", value: "uk" },
  { name: "Vietnamese", value: "vi" },
];
