import TextArea from "components/textarea";
import Player from "react-player";

const View = ({ data }: { data: any }) => {
  return (
    <div>
      <div className="border p-2 rounded-lg border-purple-300 bg-purple-50 h-[calc(100vh-100px)] overflow-auto">
        {data.youtube_id && (
          <div className="mb-5 transcript-source-url">
            <Player url={data.url} />
          </div>
        )}
        {!!data.transcript && (
          <>
            <strong className="text-2xl">Transcript</strong>
            <TextArea
              value={data.transcript?.trim()}
              ckey="text"
              onChange={() => {}}
              editable={false}
              className="!bg-transparent"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default View;
