import Button from "components/button";
import ChatHistoryItem from "components/chat-history-item";
import {
  AddIcon,
  CommentIcon,
  FinishIcon,
  PendingIcon,
  WhiteCrown,
} from "assets/icons";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getAuth, getDirectories } from "redux/settings/settings.selector";
import { IDirectory } from "types/responses";
import DirectoryService from "services/directories";
import Toast from "components/toast";
import { createPortal } from "react-dom";
import { MessageStatus } from "types/types";
import {
  removeDirectory,
  updateDirectory,
} from "redux/settings/settings.slice";
import { setShow, sidebarSelector } from "redux/sidebar/sidebar.slice";
import { isMobile } from "react-device-detect";

const View = ({ onNew }: { onNew?: () => void }) => {
  const { id } = useParams();
  const [targetShortcast, setTargetShortcast] = useState("");
  const directories = useAppSelector(getDirectories);
  const { show } = useAppSelector(sidebarSelector);
  const auth = useAppSelector(getAuth);

  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setTargetShortcast(id);
    }
  }, [id]);

  const deleteShortcast = async (id: string) => {
    try {
      await DirectoryService.deleted({
        params: { id },
        query: { sub: false, has_transcript: true },
      });
      dispatch(removeDirectory({ id }));
      setMessage({
        show: true,
        content: "Delete Success",
        type: "success",
      });
    } catch (error) {
      setMessage({
        show: true,
        content: "Something wen't wrong",
        type: "error",
      });
    }
  };

  const editShortcast = async (id: string, key: string, value: string) => {
    try {
      await DirectoryService.update({ params: { id }, body: { [key]: value } });
      dispatch(updateDirectory({ id, body: { [key]: value } }));
      setMessage({
        show: true,
        content: "Edit Success",
        type: "success",
      });
    } catch (error) {
      setMessage({
        show: true,
        content: "Something wen't wrong",
        type: "error",
      });
    }
  };

  const clickShortcast = (id: string) => {
    isMobile && dispatch(setShow(false));
    setTargetShortcast(id);
  };

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  return (
    <>
      {show && (
        <div className="fixed z-40 flex md:top-0 opacity-100 transition-all duration-300 md:sticky md:h-screen md:w-auto md:min-w-min overflow-hidden w-full">
          <div className="relative w-full h-[100vh] md:w-[284px]  pt-4 bg-gray-primary">
            <div className="py-2 md:py-4 md:mb-8">
              <Link to="/" className="flex justify-center">
                <img
                  src="/images/shortcast_logo.svg"
                  alt="logo"
                  className="hidden md:block w-[150px] h-9"
                />
              </Link>
            </div>
            <div className="pr-8 mt-10 md:m-0">
              <div className="p-4">
                <Button
                  name="New Shortcast"
                  Icon={AddIcon}
                  onClick={() => {
                    onNew && onNew();
                  }}
                  type="fourth"
                />
              </div>
              <ul className="md:w-[284px] md:h-[calc(100vh-300px)] px-2 overflow-auto">
                {directories
                  ?.filter((item: IDirectory) => item.type === "shortcast")
                  .map((shortcast: any) => (
                    <ChatHistoryItem
                      key={shortcast._id}
                      isTarget={targetShortcast === shortcast._id}
                      ckey={shortcast._id}
                      Icon={
                        shortcast.init_status === "pending"
                          ? PendingIcon
                          : shortcast.init_status === "finish"
                          ? FinishIcon
                          : CommentIcon
                      }
                      content={shortcast.name}
                      onClick={clickShortcast}
                      onDelete={deleteShortcast}
                      onEdit={editShortcast}
                    />
                  ))}
              </ul>
            </div>
            <div className="absolute bottom-0 mb-3 md:mb-8 w-full px-5">
              <Button
                Icon={WhiteCrown}
                name={
                  auth?.plans.some((plan: any) => plan.nickname !== "free")
                    ? "Premium Plan"
                    : "Upgrade to Premium"
                }
                onClick={() => navigate("/subscription")}
                className="!bg-active"
              />
            </div>
          </div>
          {message.show &&
            createPortal(
              <Toast
                visible={message.show}
                message={message.content}
                onClose={handleCloseMessage}
                type={message.type || "success"}
              />,
              document.body
            )}
        </div>
      )}
    </>
  );
};

export default View;
