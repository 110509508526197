import { createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "../../services/settings";
import CategoryService from "../../services/categories";
import ProjectService from "../../services/projects";
import PromptService from "services/prompts";
import PromptChatService from "services/prompt-chat";
import ProjectChatService from "services/project-chat";
import DirectoryService from "services/directories";
import { IDirectory } from "types/responses";
import StripeService from "services/stripe";

export const getDirectoriesAsync = createAsyncThunk(
  "setting/directories",
  async (type: string) => {
    try {
      const response = (await DirectoryService.myFiles({
        query: {
          type,
        },
      })) as IDirectory[];
      // const transcripts = (await DirectoryService.getTranscripts()) as any[];
      // response.forEach((item) => {
      //   const transcript = transcripts.find(
      //     (trans) => trans.directory_id === item.id
      //   );
      //   item.transcript = transcript || {};
      // });
      const shortcasts = response.filter((item) => item.type === "shortcast");
      const folders = response.filter((item) => item.type === "folder");
      const files = response.filter((item) => item.type === "file");
      const groupByParent = files.reduce(
        (prev: { [key: string]: IDirectory[] }, next: IDirectory) => {
          (prev[next["parent_id"]] = prev[next["parent_id"]] || []).push(next);
          return prev;
        },
        {}
      );
      const fileInFolder = folders.map((folder) => ({
        ...folder,
        childs: groupByParent[folder.id],
      }));
      return [
        ...fileInFolder,
        ...(groupByParent["undefined"] || []),
        ...shortcasts,
      ];
    } catch (error) {
      return error;
    }
  }
);

export const getLanguagesAsync = createAsyncThunk(
  "setting/languages",
  async () => {
    try {
      const response = await SettingService.getSettingsByName({
        params: {
          name: "languages",
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCategoriesAsync = createAsyncThunk(
  "setting/categories",
  async () => {
    try {
      const response = await CategoryService.getAll();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getProjectsAsync = createAsyncThunk(
  "setting/projects",
  async () => {
    try {
      const response = await ProjectService.getAll();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPromptsAsync = createAsyncThunk("setting/prompts", async () => {
  try {
    const response = await PromptService.getAll();
    return response;
  } catch (error) {
    return error;
  }
});

export const getProjectChatsAsync = createAsyncThunk(
  "settings/project-chat",
  async () => {
    try {
      const response = await ProjectChatService.getAll();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPromptChatsAsync = createAsyncThunk(
  "settings/prompt-chat",
  async () => {
    try {
      const response = await PromptChatService.getAll();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPlansAsync = createAsyncThunk("settings/plans", async () => {
  try {
    const response = await StripeService.plans();
    return response;
  } catch (error) {
    return error;
  }
});
