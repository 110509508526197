const View = () => {
  return (
    <div className="md:h-full h-[100vh] grid place-items-center ">
      <div>
        <h1 className="text-3xl md:text-5xl text-typera-secondary ">
          Welcome to <strong className="bg-clip-text bg-gradient-to-r to-purple-400 from-pink-600 text-transparent">Shortcast.AI</strong>
        </h1>
      </div>
    </div>
  );
};

export default View;
