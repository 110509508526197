import { ISource } from "types/responses";
import {
  request,
  parseErrorResponse,
  uploadRequest,
  IRequestData,
} from "./request";
import qs from "querystring";

const chat = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post("directories/chat", {
        ...req.body,
        socketId: localStorage.getItem("socketIdDirectoriesPDF"),
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const upload = (req: { file: File; bucket: string; folder: string }) =>
  new Promise((resolve, reject) => {
    const formdata = new FormData();
    formdata.append("file", req.file);
    uploadRequest()
      .post(`/${req.bucket}/${req.folder}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const { filePath, key } = res.data;
        if (filePath) resolve({ filePath, key });
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const shortcastInitSource = (req: {
  body: {
    source: string;
    upload_type: "youtube_fast" | "youtube_slow" | "file" | null;
    directory_id: string;
    language?: string;
  };
}) =>
  new Promise((resolve, reject) => {
    request()
      .post("/directories/shortcast-initSource", {
        ...req.body,
        socketId: localStorage.getItem("socketIdChat"),
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTranscripts = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/transcripts")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const generateSummary = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/directories/${req.params.id}/generate-summary`, {
        socketId: localStorage.getItem("socketIdChat"),
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const activeChatAndGenerateQuestion = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/directories/active-chat-and-generate-question/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTranscriptOfDirectory = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/transcripts/${req.params.id}/of-directory`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const initSource = (req: {
  filePath: string;
  name: string;
  key: string;
  type: string;
  parent_id: string | undefined;
}): Promise<ISource> =>
  new Promise((resolve, reject) => {
    const { filePath, name, key, parent_id, type } = req;
    request()
      .post("/directories/initSource", {
        file: { filePath, name, key, type },
        parent_id,
        socketId: localStorage.getItem("socketIdChatPDF"),
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req: {
  body: {
    name: string;
    type: string;
  };
}) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/directories`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const myFiles = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/directories/files-of-user?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req: {
  params: {
    id: string;
  };
  body: {
    [key: string]: any;
  };
}) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/directories/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleted = (req: {
  params: {
    id: string;
  };
  query: {
    sub?: boolean;
    has_transcript?: boolean;
  };
}) =>
  new Promise((resolve, reject) => {
    const querystring = qs.stringify(req.query);
    request()
      .delete(`/directories/${req.params.id}?${querystring}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const DirectoryService = {
  upload,
  myFiles,
  chat,
  initSource,
  create,
  update,
  deleted,
  shortcastInitSource,
  getTranscriptOfDirectory,
  generateSummary,
  getTranscripts,
  activeChatAndGenerateQuestion,
};

export default DirectoryService;
